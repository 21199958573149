import React, {useContext, useEffect, useState} from 'react';
import "./AdministrativeExpensesView.css";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {createColumnHelper} from "@tanstack/react-table";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import CellInfoModal from "../../../components/CellInfoModal/CellInfoModal";
import {currencyFormat} from "../../../utils/currencyFormat";
import {Skeleton, Table, Tabs} from "@mantine/core";
import {DatePickerInput} from "@mantine/dates";
import AppContext from "../../../contexts/AppContext";
import moment from "moment";

const findById = (_staffUsers, id) => {
    const sellerObj = _staffUsers.find((_user) => _user.value === id);
    return sellerObj ? sellerObj.label : id;
};

/**
 * Calculate payment details based on row data
 * @param row
 * @returns {{commission: *, cycle: null, paymentType: (string)}|{commission: *, cycle: null, paymentType: string}}
 */
function calculatePaymentDetails(row) {
    try {
        if (!row || !row.type || !row.commission || !row.installments) {
            throw new Error("Datos incompletos o inválidos en la fila");
        }

        const paymentType = row.type === "SINGLE_PAYMENT" ? "Pago único" : "Cuota";
        let totalCommission = 0;
        let cycle = "";

        if (row.type === "SINGLE_PAYMENT") {
            totalCommission = row.commission.amount;
        } else {
            totalCommission = row.installments.reduce((acc, _i) => {
                if (_i.state === "PAID" && _i.status === "CONFIRMED") {
                    cycle = _i.cycle;
                    return acc + _i.commission_amount;
                }
                return acc;
            }, 0);
        }

        const commission = currencyFormat(totalCommission);

        return { paymentType, commission, cycle };
    } catch (error) {
        console.error("Error al calcular detalles de pago:", error.message);
        return { paymentType: "Desconocido", commission: currencyFormat(0), cycle: "" };
    }
}



const SimpleSalesDetailsTable = ({data}) => {
    return (<Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>Fecha</Table.Th>
                    <Table.Th>Tipo</Table.Th>
                    <Table.Th>Capacitación</Table.Th>
                    <Table.Th>Estudiante</Table.Th>
                    <Table.Th>Comisión</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {data.map((row, index) => {
                    const { paymentType, commission, cycle } = calculatePaymentDetails(row);
                    return (
                        <Table.Tr key={index}>
                            <Table.Td>{moment(new Date(row.created_at)).format("DD/MM/YYYY")}</Table.Td>
                            <Table.Td>{`${paymentType} ${cycle}`}</Table.Td>
                            <Table.Td>{row.details[0].description}</Table.Td>
                            <Table.Td>{`${row.payer.name} ${row.payer.lastname}`}</Table.Td>
                            <Table.Td>{`$${commission} ${row.commission.currency}`}</Table.Td>
                        </Table.Tr>
                    )
                })}
            </Table.Tbody>
        </Table>
    </Table.ScrollContainer>)
}

function AdministrativeExpensesView() {
    const {authToken} = useContext(AuthContext);
    const { staffUsers } = useContext(AppContext)
    const [expensesData, setExpensesData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cellModal, setCellModal] = useState(null)

    // Get initDate as this month's first day
    const firstDayOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    const currentDay = new Date();
    const [dateFilter, setDateFilter] = useState([firstDayOfThisMonth, currentDay]);

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            cell: (info) => info.getValue(),
            header: "Nombre",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: "includesString",
        }),
        columnHelper.accessor("", {
            cell: (info) => {
                const {teacherInfo, lessonsData} = info.row.original;
                if (!teacherInfo.lessonFee) return null;
                return (
                    <span className="AdministrativeExpensesView__cell--center">
                    {currencyFormat(teacherInfo.lessonFee * lessonsData.length)}
                </span>);
            },
            header: "Total",
            enableColumnFilter: false,
            enableSorting: true,
            size: 88,
        }),
        columnHelper.accessor("teacherInfo.feeCurrency", {
            cell: (info) =>
                <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue()}
                </span>,
            header: "Moneda",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'ARS', label: 'ARS'},
                {value: 'USD', label: 'USD'},
            ],
            size: 88,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.teacherInfo.feeCurrency.toLowerCase() === filterValue.toLowerCase();
            }
        }),
        columnHelper.accessor("teacherInfo", {
            cell: (info) => {
                if (!info.getValue().lessonFee) return null;
                return <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue().lessonFee} {info.getValue().feeCurrency}
                    </span>
            },
            header: "Valor clase",
            enableColumnFilter: false,
            enableSorting: true,
            size: 104,
        }),
        columnHelper.accessor("lessonsData", {
            cell: (info) =>
                <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue().length}
                </span>,
            header: "Clases",
            enableColumnFilter: false,
            enableSorting: true,
            size: 88,
        }),
        columnHelper.accessor("lessonsData", {
            cell: (info) => <span
                className="AdministrativeExpensesView__cell"
                onClick={() => setCellModal({
                    text: <ul>{info.getValue().map(_l => <li className="AdministrativeExpensesView__ul">{_l.name}</li>)}</ul>,
                    title: 'Clases dictadas'
                })}
            >{info.getValue().map(_l => _l.name).join(",")}</span>,
            header: "Detalle",
            enableColumnFilter: false,
            enableSorting: true,
        }),
    ];
    const modColumns = [
        columnHelper.accessor("name", {
            cell: (info) => info.getValue(),
            header: "Nombre",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: "includesString",
        }),
        columnHelper.accessor("", {
            cell: (info) => {
                const {staffInfo, lessonsData} = info.row.original;
                if (!staffInfo.lessonFee) return null;
                return (
                    <span className="AdministrativeExpensesView__cell--center">
                    {currencyFormat(staffInfo.lessonFee * lessonsData.length)}
                </span>);
            },
            header: "Total",
            enableColumnFilter: false,
            enableSorting: true,
            size: 88,
        }),
        columnHelper.accessor("staffInfo.feeCurrency", {
            cell: (info) =>
                <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue()}
                </span>,
            header: "Moneda",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'ARS', label: 'ARS'},
                {value: 'USD', label: 'USD'},
            ],
            size: 88,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.staffInfo.feeCurrency.toLowerCase() === filterValue.toLowerCase();
            }
        }),
        columnHelper.accessor("staffInfo", {
            cell: (info) => {
                if (!info.getValue().lessonFee) return null;
                return <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue().lessonFee} {info.getValue().feeCurrency}
                    </span>
            },
            header: "Valor clase",
            enableColumnFilter: false,
            enableSorting: true,
            size: 104,
        }),
        columnHelper.accessor("lessonsData", {
            cell: (info) =>
                <span className="AdministrativeExpensesView__cell--center">
                    {info.getValue().length}
                </span>,
            header: "Clases",
            enableColumnFilter: false,
            enableSorting: true,
            size: 88,
        }),
        columnHelper.accessor("lessonsData", {
            cell: (info) => <span
                className="AdministrativeExpensesView__cell"
                onClick={() => setCellModal({
                    text: <ul>{info.getValue().map(_l => <li className="AdministrativeExpensesView__ul">{_l.name}</li>)}</ul>,
                    title: 'Clases dictadas'
                })}
            >{info.getValue().map(_l => _l.name).join(",")}</span>,
            header: "Detalle",
            enableColumnFilter: false,
            enableSorting: true,
        }),
    ];
    const salesColumns = [
        columnHelper.accessor("sellerId", {
            cell: (info) => {
                const sellerId = info.getValue();
                return findById(staffUsers, sellerId);
            },
            header: "Vendedor",
            enableColumnFilter: false,
            enableSorting: true
        }),
        columnHelper.accessor("commissions.totalARS", {
            cell: (info) => {
                return `$ ${currencyFormat(info.getValue())}`;
            },
            header: "Comisiones ARS",
            enableColumnFilter: false,
            enableSorting: true,
            size: 120,
        }),
        columnHelper.accessor("commissions.totalUSD", {
            cell: (info) => {
                return `$ ${currencyFormat(info.getValue())}`;
            },
            header: "Comisiones USD",
            enableColumnFilter: false,
            enableSorting: true,
            size: 120,
        }),
        columnHelper.accessor("sales", {
            cell: (info) =>
                <span
                    className="AdministrativeExpensesView__cell--center"
                    style={{cursor: 'pointer'}}
                    onClick={() => setCellModal({
                        // Or use TanStack instead
                        text: <SimpleSalesDetailsTable
                            data={info.row.original.sales}
                        />,
                        title: `Detalle de ventas de ${findById(staffUsers, info.row.original.sellerId)}`,
                        darkMode: false
                    })}
                >
                    Ver detalles
                </span>,
            header: "Detalle",
            enableColumnFilter: false,
            enableSorting: true,
            size: 88
        })
    ];

    // DATA INITIALIZATION
    const init = async () => {
        setLoading(true);
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/getExpenses?initDate=${dateFilter[0]}&endDate=${dateFilter[1]}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const expensesResponse = await axios.get(url, config);

        // Sort data by lessonsData length
        expensesResponse.data.teachers.sort((a, b) => b.lessonsData.length - a.lessonsData.length);
        expensesResponse.data.moderators.sort((a, b) => b.lessonsData.length - a.lessonsData.length);

        setExpensesData(expensesResponse.data);
        setLoading(false);
    };

    useEffect(() => {
        if (dateFilter[0] && dateFilter[1])
            init();
    }, [dateFilter]);

    return (
        <div className="AdministrativeExpensesView__container">
            <div className="AdministrativeExpensesView__filters">
                <DatePickerInput
                    classNames={{ root: "moderationContainer-DatePicker-root" }}
                    type="range"
                    label="Mostrando datos de:"
                    value={dateFilter}
                    onChange={(value) => setDateFilter(value)}
                />
                <span>
                    * Por defecto se muestran datos del mes actual (desde el 1ro hasta la fecha actual)
                </span>
            </div>
            {loading && < Skeleton h='400px' w='100%' />}
            {!loading && <Tabs
                defaultValue="Profesores"
                style={{width: "100%"}}
            >
                <Tabs.List>
                    <Tabs.Tab value="Profesores">
                        Profesores
                    </Tabs.Tab>
                    <Tabs.Tab value="Moderadores">
                        Moderadores
                    </Tabs.Tab>
                    <Tabs.Tab value="Vendedores">
                        Vendedores
                    </Tabs.Tab>
                </Tabs.List>

                {expensesData?.teachers &&
                <Tabs.Panel value="Profesores" pt={20}>
                    <TanStackTable
                        loading={loading}
                        data={expensesData.teachers}
                        columns={columns}
                    />
                </Tabs.Panel>}

                {expensesData?.moderators &&
                <Tabs.Panel value="Moderadores" pt={20}>
                    <TanStackTable
                        loading={loading}
                        data={expensesData.moderators}
                        columns={modColumns}
                    />
                </Tabs.Panel>
                }
                {expensesData?.sales &&
                <Tabs.Panel value="Vendedores" pt={20}>
                    <TanStackTable
                        loading={loading}
                        data={expensesData.sales}
                        columns={salesColumns}
                    />
                </Tabs.Panel>
                }
            </Tabs>}
            <CellInfoModal opened={cellModal} onCloseFunct={setCellModal} text={cellModal?.text}
                           title={cellModal?.title} darkMode={cellModal?.darkMode}/>
        </div>
    );
}

export default AdministrativeExpensesView;