import React, {useEffect, useState} from 'react'
import {Button, Grid, Loader, NumberInput} from '@mantine/core'
import {calculateAmountValues} from "../../../../utils/calculateAmountValues";

export default function AdministrativePaymentsForm({form, isLoading}) {
    const {amountAfterDiscount} = calculateAmountValues(form.values.rowData);
    const [advancedConfigEnabled, setAdvancedConfigEnabled] = useState(false);

    useEffect(() => {
        // If the amount received on platform is changed,
        if (form.values.amountReceivedOnPlatform !== 0) {
            // Update the platformCommission percentage and amount
            const platformCommissionPercentage = ((1 - (form.values.amountReceivedOnPlatform / amountAfterDiscount)) * 100).toFixed(1)
            form.setFieldValue('platformCommission.percentage', parseFloat(platformCommissionPercentage))
            form.setFieldValue('platformCommission.amount', amountAfterDiscount - form.values.amountReceivedOnPlatform)

            const commissionPercentage = form.values.rowData?.commission?.percentage ?? 0;
            // Update the salesCommission percentage and amount
            const salesCommissionAmount = (form.values.amountReceivedOnPlatform * (commissionPercentage / 100)).toFixed(1);
            form.setFieldValue('salesCommission.percentage', commissionPercentage)
            form.setFieldValue('salesCommission.amount', parseFloat(salesCommissionAmount))
        }
    }, [form.values.amountReceivedOnPlatform]);

    useEffect(() => {
        // When advanced config is enabled, the sales commission are updated based on input changes
        if (advancedConfigEnabled) {
            // When the sales commission percentage is changed, the amount is updated
            if (form.values.salesCommission.percentage !== 0) {
                const salesCommissionAmount = (form.values.amountReceivedOnPlatform * (form.values.salesCommission.percentage/100)).toFixed(1)
                form.setFieldValue('salesCommission.amount', parseFloat(salesCommissionAmount))
            }
        }
    }, [form.values.salesCommission.percentage]);

    return (
        <Grid style={{width: "100%"}} justify="center" align="center">
            <Grid.Col span={12}>
                <NumberInput
                    label='Monto recibido en plataforma ($)'
                    {...form.getInputProps("amountReceivedOnPlatform")}
                    withAsterisk
                    min={0}
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, sm: 6}}>
                <NumberInput
                    label='Comisión de plataforma ($)'
                    {...form.getInputProps("platformCommission.amount")}
                    withAsterisk
                    min={0}
                    disabled
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, sm: 6}}>
                <NumberInput
                    label='Comisión de plataforma (%)'
                    {...form.getInputProps("platformCommission.percentage")}
                    withAsterisk
                    min={0}
                    max={100}
                    disabled
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, sm: 6}}>
                <NumberInput
                    label='Comisión de ventas (%)'
                    {...form.getInputProps("salesCommission.percentage")}
                    withAsterisk
                    min={0}
                    step={0.1}
                    max={100}
                    disabled={!advancedConfigEnabled}
                />
            </Grid.Col>
            <Grid.Col span={{base: 12, sm: 6}}>
                <NumberInput
                    label='Comisión de ventas ($)'
                    {...form.getInputProps("salesCommission.amount")}
                    withAsterisk
                    min={0}
                    disabled
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    variant={"outline"}
                    color="secondaryColor"
                    style={{width: "100%", alignSelf: "flex-end", margin: "8px 0"}}
                    onClick={() => setAdvancedConfigEnabled(!advancedConfigEnabled)}
                >
                    {advancedConfigEnabled ? "Ocultar configuración avanzada" : "Configuración avanzada de comisiones"}
                </Button>
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    type='submit'
                    disabled={isLoading}
                >
                    {isLoading ? < Loader size={30} color='#fff'/> : 'Guardar pago'}
                </Button>
            </Grid.Col>
        </Grid>
    )
}
