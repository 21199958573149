import React, {useEffect, useState, useMemo, useContext, useCallback} from "react";
import AppContext from "./AppContext.js";
import Axios from "axios";
import AuthContext from "../AuthContext";
import showNotification from "../../utils/showNotification";
import {ERROR_HANDLER} from "../../utils/error-handler.js";

const AppContextProvider = ({children}) => {
    const {authToken} = useContext(AuthContext);
    const [isReady, setIsReady] = useState(false);
    const [academicUnits, setAcademicUnits] = useState([]);
    const [avals, setAvals] = useState([]);
    const [staffUsers, setStaffUsers] = useState([]);
    const [teachersUsers, setTeachersUsers] = useState([]);
    const [materialsData, setMaterialsData] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [bankAccountsData, setBankAccountsData] = useState([]);
    const [bankAccountsOptions, setBankAccountsOptions] = useState([]);
    const [currentUser, setCurrentUser] = useState(null)
    const [modified, setModified] = useState(false);
    const [dollarValue, setDollarValue] = useState(null);
    const handleModified = useCallback(() => {
        setModified(!modified)
    }, [modified]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}campus/login`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + authToken,
                    },
                })
                setCurrentUser(response.data);

                const {data} = await Axios.get(`${process.env.REACT_APP_BACKEND_SERVER}admin/context`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + authToken,
                    }
                })

                setAcademicUnits(data.academicUnits);
                setAvals(data.avals);
                setStaffUsers(data.staff);
                setTeachersUsers(data.teachers);
                setMaterialsData(data.materials);
                setMaterialOptions(data.materials.map(material => ({
                    value: material._id,
                    label: material.name
                })));
                setBankAccountsData(data.bankAccounts);
                setBankAccountsOptions(data.bankAccounts.map(bankAccount => ({
                    value: bankAccount._id,
                    label: `${bankAccount.accountHolder.toUpperCase()} - ${bankAccount.accountBank.toUpperCase()}`
                })));
                // console.log("response", data)
            } catch (error) {
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Ha ocurrido un problema al cargar tus datos.",
                    message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                })
            } finally {
                setIsReady(true);
            }

            try {
                const dollarOfficialData = await Axios.get(`https://dolarapi.com/v1/dolares/oficial`)
                // Check if the response is valid
                if (!dollarOfficialData.data || !dollarOfficialData.data.venta) {
                    throw new Error("No se pudo obtener el valor del dolar");
                }
                setDollarValue(dollarOfficialData.data.venta)
            } catch (error) {
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Ha ocurrido un problema al cargar el valor del dolar.",
                    message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                })
            }
        }

        init();
    }, [modified]);

    const context = useMemo(
        () => ({
            isReady,
            academicUnits,
            avals,
            staffUsers,
            teachersUsers,
            materialsData,
            materialOptions,
            bankAccountsData,
            bankAccountsOptions,
            currentUser,
            dollarValue,
            handleModified,
        }),
        [
            isReady,
            academicUnits,
            avals,
            staffUsers,
            teachersUsers,
            materialsData,
            materialOptions,
            bankAccountsData,
            bankAccountsOptions,
            currentUser,
            dollarValue,
            handleModified,
        ]
    );
    return (
        <AppContext.Provider value={context}>{children}</AppContext.Provider>
    );
};
export default AppContextProvider;
